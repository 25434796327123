import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Summary from 'content/blog/en/vercel-like-paas-summary';
export const _frontmatter = {
  "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part IV",
  "subtitle": "Kubernetes manifests",
  "cover": "k8s+gitlab.webp",
  "description": "This third part explains how to write Dockerfiles and set up applications\nto hande incoming connection when deployed to a Kubernetes cluster.\n",
  "meta": {
    "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part IV",
    "description": "How to write Dockerfiles and set up applications to hande incoming connection"
  },
  "tags": ["Kubernetes", "GitOps", "DevOps", "Automation"],
  "publishedAt": "2022-02-11T09:00:00",
  "published": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This article is the `}<strong parentName="p">{`fourth part`}</strong>{` of the `}<em parentName="p">{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps`}</em>{` series.`}</p>
    <Summary mdxType="Summary" />
    <hr></hr>
    <p><em parentName="p">{`In previous parts `}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part1"
        }}>{`I've set up a Kubernetes cluster`}</a>{`,
`}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part2"
        }}>{`configured GitLab repositories, pipeline stages`}</a>{`,
`}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part3"
        }}>{`and then Dockerfiles`}</a>{`.`}</em></p>
    <p><em parentName="p">{`Now, I'm going to create three `}<a parentName="em" {...{
          "href": "https://kubernetes.io/docs/reference/glossary/?all=true#term-manifest"
        }}>{`manifests`}</a>{`
to describe resources I want to add to the Kubernetes cluster:
an `}<a parentName="em" {...{
          "href": "https://kubernetes.io/docs/concepts/services-networking/ingress/"
        }}>{`Ingress`}</a>{`
rule, a `}<a parentName="em" {...{
          "href": "https://kubernetes.io/docs/concepts/services-networking/service/"
        }}>{`Service`}</a>{`
and a `}<a parentName="em" {...{
          "href": "https://kubernetes.io/docs/concepts/workloads/controllers/deployment/"
        }}>{`Deployment`}</a>{`.`}</em></p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#0"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#1"
        }}>{`Add an `}<em parentName="a">{`Ingress`}</em>{` rule to route requests to a `}<em parentName="a">{`Service`}</em></a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#2"
        }}>{`The Dockerfile and the docker build command`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#3"
        }}>{`Kubernetes manifests and resource configuration`}</a></li>
    </ol>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@0"
      }}>{`Introduction`}</a></h2>
    <p>{`Previously, I've modeled how traffic flows from client to application with the
following diagram, in this part I'm setting up components 4, 5 and 6:`}</p>
    <pre><code parentName="pre" {...{}}>{`✓ 1.client       DNS, 443/TCP - part I
  ↓
✓ 2.host         k0s installed - part I
  ↓
✓ 3.ingress      ingress-nginx installed - part I
  ↓
  4.service
  ↓
  5.pod
  ↓
  6.container
  ↓
✓ 7.application  Dockerfile - part III
`}</code></pre>
    <p>{`The Kubernetes documentation illustrates this flow
`}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/concepts/services-networking/ingress/#what-is-ingress"
      }}>{`in a similar way`}</a>{`,
and these components sit in the grey rectangle with the `}<em parentName="p">{`cluster`}</em>{` label in the
following picture:`}</p>
    <img alt="Traffic flow according to the Kubernetes documentation" caption="Traffic flow according to Kubernetes documentation" src="ingress.webp" width={70} />
    <p>{`For that purpose, I've created three Kubernetes manifests.
They are identical throughout the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs"
      }}>{`Node.js`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/php"
      }}>{`PHP`}</a>{`, `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/python"
      }}>{`Python`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/ruby"
      }}>{`Ruby`}</a>{` repositories so file excerpts hold true
for any application example.`}</p>
    <p>{`To keep things simple, I'm not using any template engine or tool such as `}<a parentName="p" {...{
        "href": "https://kustomize.io/"
      }}>{`Kustomize`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://helm.sh/"
      }}>{`Helm`}</a>{`, `}<a parentName="p" {...{
        "href": "https://jsonnet.org/"
      }}>{`Jsonnet`}</a>{` or `}<a parentName="p" {...{
        "href": "https://carvel.dev/ytt/"
      }}>{`ytt`}</a>{`.`}</p>
    <p>{`Instead, I'm doing a simple `}<em parentName="p">{`find`}</em>{` and `}<em parentName="p">{`replace`}</em>{` with `}<inlineCode parentName="p">{`__TOKEN__`}</inlineCode>{` strings in manifests files,
I've explained this task in `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part2/#5-2"
      }}>{`part II`}</a>{`.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@1"
      }}>{`1. Add an `}<em parentName="a">{`Ingress`}</em>{` rule to route requests to a `}<em parentName="a">{`Service`}</em></a></h2>
    <p>{`An `}<em parentName="p">{`Ingress`}</em>{` is not a component in itself, in the sense that it doesn't
translates to a pod deployed to the cluster, with its own IP address on the
cluster's internal network.`}</p>
    <p>{`It's a routing rule that instructs the cluster's `}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/concepts/services-networking/ingress-controllers/"
      }}><em parentName="a">{`ingress controller`}</em></a>{`
to forward incoming connections to a certain `}<em parentName="p">{`Service`}</em>{` according to a rule.
Next section will explain what a `}<em parentName="p">{`Service`}</em>{` is.`}</p>
    <p>{`In my case, since I've installed `}<em parentName="p">{`ingress-nginx`}</em>{`, this new rule will tell to the
`}<inlineCode parentName="p">{`ingress-nginx-162...`}</inlineCode>{` pod `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part1#5"
      }}>{`I was talking about in part I`}</a>{`,
to forward traffic received form the host to the `}<em parentName="p">{`Service`}</em>{` I'll defined in the
next section.`}</p>
    <p>{`So, to define such rule I create an `}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/concepts/services-networking/ingress/"
      }}>{`Ingress`}</a>{`
resource with the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/manifests/ingress.yml"
      }}><inlineCode parentName="a">{`ingress.yml`}</inlineCode></a>{`
file defines an
rule to route a specific url to a specific service:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: networking.k8s.io/v1
kind: Ingress
---
spec:
  ingressClassName: nginx <-- when this ingress-controller receives requests
  rules:
    - host: __KUBE_INGRESS_HOST__  <-- route requests to this domain name
      http:
        paths:
          - path: /  <-- regardless of the requested path
            backend:
              service:
                name: api  <-- to this service (in the same namespace)
                port:
                  number: 3000  <-- using this TCP port
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@1-1"
      }}>{`Watching traffic flows`}</a></h3>
    <p>{`So, adding an `}<em parentName="p">{`Ingress`}</em>{` means , what does On the other hand, the `}<inlineCode parentName="p">{`ingress-nginx`}</inlineCode>{` controller transletes to a pod with its own
IP address, running `}<inlineCode parentName="p">{`nginx`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ kubectl describe pod -A -l app.kubernetes.io/name=ingress-nginx
Name:         ingress-nginx-1644337728-controller-5cdf6b55b9-55qj4
...
Status:       Running
IP:           172.31.12.80
Containers:
  controller:
    Ports:         80/TCP, 443/TCP, 8443/TCP
    Host Ports:    80/TCP, 443/TCP, 8443/TCP
`}</code></pre>
    <p>{`A simple way to see traffic being forwarded from this pod to a service is to show this pod's
log stream while querying a deployed application. Here is the pod's output while running
a `}<inlineCode parentName="p">{`curl`}</inlineCode>{` command form another terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# run this command in one terminal:
$ kubectl logs -f $(kubectl get pod -A \\
  -l app.kubernetes.io/name=ingress-nginx -o name)

---

# run this command in a second terminal:
$ curl https://7c77eb36.nodejs.k0s.gaudi.s

---

# then the first terminal shows this new line:
192.123.213.23 - - [07/Mar/2022:20:00:10 +0000] "GET / HTTP/2.0" 200 15 "-" "curl/7.68.0" 34 0.001 [nodejs-7c77eb36-api-3000] [] 10.244.0.24:3000 25 0.004 200 ee266baf44f3cfd0838e28fa1ff79785
`}</code></pre>
    <p>{`This log line shows the `}<inlineCode parentName="p">{`curl/7.68.0`}</inlineCode>{` client request to be forwared to `}<inlineCode parentName="p">{`nodejs-7c77eb36-api-3000`}</inlineCode>{`.
Since the URL I'm requesting points to
the `}<inlineCode parentName="p">{`api`}</inlineCode>{` service, on port `}<inlineCode parentName="p">{`3000`}</inlineCode>{` and deployed in the `}<inlineCode parentName="p">{`nodejs-7c77eb36`}</inlineCode>{` namespace,
which does exist:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ kubectl get service api -n nodejs-7c77eb36 \\
  -o custom-columns=NAMESPACE:metadata.namespace,SERVICE:metadata.name,PORT:spec.ports[*].targetPort
NAMESPACE         SERVICE   PORT
nodejs-7c77eb36   api       3000
`}</code></pre>
    <h3>{`2. Add a `}<em parentName="h3">{`Service`}</em>{` to forward request to `}<em parentName="h3">{`Pods`}</em></h3>
    <p>{`On the other hand, a `}<em parentName="p">{`Service`}</em>{` is an abstract component that has an IP address and
act as a load balancer in front of a pool of pods.`}</p>
    <p>{`Because `}<em parentName="p">{`Pods`}</em>{` can be created and deleted at will, the Service act as a single immutable endpoint,
meaning it has a fixed IP address. Then traffic is balanced between members of a Pod pool.
The
file defines a `}<em parentName="p">{`Service`}</em>{` resource that
To enter the pool, pods must use a specific selector as it's declared in the application's'
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/manifests/service.yml"
      }}><inlineCode parentName="a">{`service.yml`}</inlineCode></a>{`
manifest:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: v1
kind: Service
---
spec:
  type: ClusterIP
  ports:
    - name: http
      port: 80 <-- service listens to this port
      targetPort: 3000 <-- and routes traffic to this pod's port
      protocol: TCP
  selector:
    app: api <-- pods must use this value to enter the pool
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@3"
      }}>{`3. `}<em parentName="a">{`Pod`}</em>{` forwards request to `}<em parentName="a">{`container`}</em></a></h3>
    <p>{`Pods are defined in the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/manifests/deployment.yml"
      }}><inlineCode parentName="a">{`deployment.yml`}</inlineCode></a>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: apps/v1
kind: Deployment
`}</code></pre>
    <p>{`I set how many pods I want to start:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`spec:
  replicas: 1 <-- the size of the pool
`}</code></pre>
    <p>{`Which service pods belongs to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`selector:
  matchLabels:
    app: api <-- selector must match service
`}</code></pre>
    <p>{`The credentials to pull the Docker image from the `}<em parentName="p">{`GitLab Container Registry`}</em>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`spec:
  imagePullSecrets:
    - name: gitlab-registry
`}</code></pre>
    <p>{`I need only one container running the image built in `}<a parentName="p" {...{
        "href": ""
      }}>{`section 1`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`containers:
  - name: node
    image: __CI_REGISTRY_IMAGE__:__CI_COMMIT_SHORT_SHA__
`}</code></pre>
    <p>{`I don't want to lock any host's resources for these pods. This is where I save a lot compared to other managed PaaS because I can wisely overcommit resources:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`resources:
  requests:
    cpu: '0'
    memory: '0'
`}</code></pre>
    <p>{`I don't want to allow a pod to eat all the host's resources so I limit how much a pod can use`}<anote id="2" />{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`limits:
  cpu: '0.125'
  memory: 64M
`}</code></pre>
    <p>{`And I expose the correct port:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`ports:
  - containerPort: 3000
`}</code></pre>
    <h3>{`6. `}<em parentName="h3">{`container`}</em>{` receives traffic and serve the `}<em parentName="h3">{`application`}</em></h3>
    <p>{`It is critical to use the port the application is listening to in `}<em parentName="p">{`deployment.yml`}</em>{` configuration (`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/manifests/deployment.yml#L31"
      }}>{`containerPort`}</a>{`) and therefore in the `}<em parentName="p">{`service.yml`}</em>{` (`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/manifests/service.yml#L13"
      }}>{`targetPort`}</a>{`) and `}<em parentName="p">{`ingress.yml`}</em>{` configuration (`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/manifests/ingress.yml#L25"
      }}>{`service.port.number`}</a>{`).`}</p>
    <p>{`In every application, I'm using the `}<a parentName="p" {...{
        "href": ""
      }}><inlineCode parentName="a">{`COMMIT_SHORT_HASH`}</inlineCode>{` variable`}</a>{` (exists only in during pipeline runtime) to assign a value to the `}<inlineCode parentName="p">{`COMMIT`}</inlineCode>{` environmental variable (stored in the Docker image). Then, I can read this value from code:`}</p>
    <p><a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/app.js#L10"
      }}>{`Node.js`}</a>{`: `}<inlineCode parentName="p">{`process.env.COMMIT`}</inlineCode>{`
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/php/-/blob/master/app.php#L5"
      }}>{`PHP`}</a>{`: `}<inlineCode parentName="p">{`getenv('COMMIT')`}</inlineCode>{`
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/python/-/blob/master/app.py#L14"
      }}>{`Python`}</a>{`: `}<inlineCode parentName="p">{`os.environ.get("COMMIT")`}</inlineCode>{`
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/ruby/-/blob/master/app.rb#L12"
      }}>{`Ruby`}</a>{`: `}<inlineCode parentName="p">{`ENV['RUBY_VERSION']`}</inlineCode></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`deploy app`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples"
      }}>{`https://gitlab.com/k0s-examples`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs"
      }}>{`Node.js`}</a>{` `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/php"
      }}>{`PHP`}</a>{` `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/python"
      }}>{`Python`}</a>{` `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/ruby"
      }}>{`Ruby`}</a></p>
    <hr></hr>
    <note id="1">
  Be aware that if you want to overcommit resources, as the
  <a href="https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#requests-and-limits" target="_blank">
    documentation
  </a> notes: <em>if a container specifies its own memory limit, but does not specify a memory request, Kubernetes automatically assigns a memory request that matches the limit.</em>
    </note>
    <note id="2">
  Remember that Kubernetes doesn't gently refuse access to more resource, it
  usually{' '}
  <a href="https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#requests-and-limits" target="_blank">
    terminates
  </a>
  (kills) hungry pods. A good reason not to run shell commands, such as migration
  or cron jobs, in running pods that serves web trafic.
    </note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      